import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CircularProgress from "@material-ui/core/CircularProgress";
import useSession from "../auth/useSession";
import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import HideOnScroll from "../lib/HideOnScroll";
import ScrollTop from "../lib/ScrollTop";
import { Avatar } from "@material-ui/core";
import { md5 } from "../lib/md5";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  bump: {
    marginBottom: theme.spacing(10)
  },
  nav: {
    width: 250
  }
}));

export default function BaseHeader(): JSX.Element {
  const classes = useStyles();

  const [navOpen, setNavOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const history = useHistory();

  const session = useSession();

  if (!session) {
    return <CircularProgress />;
  }

  const hasSession = session.isValid();

  const gravatar = useMemo(() => {
    if (!hasSession) {
      return undefined;
    }

    const hash = md5(session.username?.toLowerCase() as string);
    return `https://www.gravatar.com/avatar/${hash.toLowerCase()}`;
  }, [session, hasSession]);

  const openNav = () => {
    setNavOpen(true);
  };

  const closeNav = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavOpen(false);
  };

  const navHome = () => {
    history.push("/");
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    history.push("/login");
  };

  const handleLogout = () => {
    session.logout();
    handleClose();
    history.push("/");
  };

  const handleHome = () => {
    handleClose();
    history.push("/");
  };

  const handleProfile = () => {
    handleClose();
    history.push("/hello");
  };

  return (
    <>
      <HideOnScroll>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={openNav}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
              onClick={handleHome}
            >
              Paraxanthine
            </Typography>
            {!hasSession && (
              <div>
                <Button
                  onClick={handleLogin}
                  color="secondary"
                  size="small"
                  variant="contained"
                >
                  Login / Sign Up
                </Button>
              </div>
            )}
            {hasSession && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar src={gravatar} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleProfile}>Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <SwipeableDrawer
        anchor="left"
        open={navOpen}
        onOpen={openNav}
        onClose={closeNav}
      >
        <div
          className={classes.nav}
          role="presentation"
          onClick={closeNav}
          onKeyDown={closeNav}
        >
          <List>
            <ListItem onClick={navHome}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <div className={classes.bump} id="back-to-top-anchor" />
    </>
  );
}
