import JWK from "./JWK";
import JWT from "./JWT";

export default class JWKS {
  private readonly keys: JWK[];
  constructor(keys: JWK[]) {
    this.keys = keys;
  }

  async initialize(): Promise<void> {
    await Promise.all(this.keys.map((key) => key.import()));
  }

  find(jwt: JWT): JWK {
    const jwk = this.keys.find((key) => key.kid === jwt.kid);

    if (!jwk) {
      throw new Error("invalid kid");
    }

    return jwk;
  }
}
