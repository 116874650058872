import React from "react";
import Container from "@material-ui/core/Container";
import { useHelloQuery } from "../graphql/graphql";
import { CircularProgress } from "@material-ui/core";

export default function Home(): JSX.Element {
  const { loading, data } = useHelloQuery();

  if (loading) {
    return (
      <Container maxWidth="xs">
        <CircularProgress />
      </Container>
    );
  }

  return <Container maxWidth="xs">{JSON.stringify(data)}</Container>;
}
