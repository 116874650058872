import JWKeys from "./JWKeys";
import { decode, decodeUint8Array } from "../../lib/base64Url";

export interface JWTHeader {
  kid: string;
  alg: string;
}

export interface JWTPayload {
  sub: string;
  aud: string;
  email_verified: boolean;
  token_use: string;
  auth_time: string;
  iss: string;
  "cognito:username": string;
  exp: string;
  given_name: string;
  iat: string;
  email: string;
  jti: string;
  origin_jti: string;
}

export type JWTSignature = Uint8Array;

export default class JWT {
  private readonly raw: string;

  private readonly Header: JWTHeader;
  private readonly rawHeader: string;
  private readonly Payload: JWTPayload;
  private readonly rawPayload: string;
  private readonly Signature: JWTSignature;
  private readonly rawSignature: string;

  constructor(raw: string) {
    this.raw = raw;

    if (!this.raw) {
      throw new Error("empty JWT");
    }

    const parts = this.raw.split(".").filter((part) => !!part);

    if (parts.length !== 3) {
      throw new Error("malformed JWT");
    }

    const [rawHeader, rawPayload, rawSignature] = parts;

    try {
      this.rawHeader = rawHeader;
      this.Header = JSON.parse(atob(decode(this.rawHeader)));
    } catch (ex) {
      throw new Error("malformed JWT Header");
    }

    try {
      this.rawPayload = rawPayload;
      this.Payload = JSON.parse(atob(decode(this.rawPayload)));
    } catch (ex) {
      throw new Error("malformed JWT Payload");
    }

    this.rawSignature = rawSignature;
    try {
      this.Signature = decodeUint8Array(this.rawSignature);
    } catch (ex) {
      throw new Error("malformed JWT Signature");
    }
  }

  get kid(): string {
    return this.Header.kid;
  }

  get email(): string {
    return this.Payload.email;
  }

  get bearer(): string {
    return this.raw;
  }

  async verify(): Promise<boolean> {
    const jwk = JWKeys.find(this);

    if (jwk.alg !== this.Header.alg) {
      throw new Error("invalid algorithm");
    }

    const verifier = jwk.verifier();

    const encoder = new TextEncoder();

    const sig = this.Signature;
    const data = encoder.encode(`${this.rawHeader}.${this.rawPayload}`);

    const valid = await verifier(sig, data);

    if (!valid) {
      throw new Error("invalid JWT");
    }

    return valid;
  }
}
