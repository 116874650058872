import React, { useMemo } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink
} from "@apollo/client";
import useSession from "../auth/useSession";

export interface GraphProviderOptions {
  uri: string;
}

type GraphProviderProps = {
  children: JSX.Element;
  options: GraphProviderOptions;
};

export default function GraphProvider({
  children,
  options
}: GraphProviderProps): JSX.Element {
  const session = useSession();

  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: new HttpLink({ uri: options.uri, fetch: session?.fetcher() })
    });
  }, [session]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
