import JWT from "./JWT";

export interface TokenRaw {
  id_token: string;
  access_token: string;
  refresh_token: string;
  client_id: string;
  expires_in: string;
  token_type: string;
  expires?: number;
}

export default class Token {
  private readonly raw: TokenRaw;
  readonly idToken: JWT;
  readonly accessToken: JWT;
  readonly expires: number;

  constructor(raw: TokenRaw) {
    this.raw = raw;

    this.expires =
      this.raw.expires || Date.now() + Number.parseInt(this.raw.expires_in);

    this.idToken = new JWT(this.raw.id_token);
    this.accessToken = new JWT(this.raw.access_token);
  }

  get username(): string {
    return this.idToken.email;
  }

  get refreshToken(): string {
    return this.raw.refresh_token;
  }

  async validate(): Promise<boolean> {
    if (!this.raw) {
      return false;
    }

    const validIdToken = await this.idToken.verify();

    if (!validIdToken) {
      return false;
    }

    const validAccessToken = await this.accessToken.verify();

    if (!validAccessToken) {
      return false;
    }

    if (this.expires <= Date.now()) {
      return false;
    }

    return true;
  }

  toString(): string {
    return this.toJSON();
  }

  toJSON(): string {
    return JSON.stringify({
      ...this.raw,
      expires: this.expires
    });
  }
}
