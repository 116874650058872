export function i2hex(i: number): string {
  return `0${i.toString(16)}`.slice(-2);
}

export default function hexrand(size = 8): string {
  if (size <= 0) {
    throw new Error("size must be greater than 0");
  }

  const bytes = new Uint8Array(size);
  window.crypto.getRandomValues(bytes);

  return Array.from(bytes).map(i2hex).join("");
}
