export interface JWK_RAW {
  alg: string;
  e: string;
  kid: string;
  kty: string;
  n: string;
  use: string;
}

export default class JWK {
  private readonly raw: JWK_RAW;
  private key: CryptoKey | undefined;

  constructor(jwkRaw: JWK_RAW) {
    this.raw = jwkRaw;
  }

  async import(): Promise<void> {
    this.key = await crypto.subtle.importKey(
      "jwk",
      this.raw,
      {
        name: "RSASSA-PKCS1-v1_5",
        hash: "SHA-256"
      },
      true,
      ["verify"]
    );
  }

  get kid(): string {
    return this.raw.kid;
  }

  get alg(): string {
    return this.raw.alg;
  }

  verifier(): (signature: ArrayBuffer, data: ArrayBuffer) => Promise<boolean> {
    return async (signature: ArrayBuffer, data: ArrayBuffer) => {
      if (!this.key) {
        throw new Error("uninitialized key");
      }

      return crypto.subtle.verify(
        {
          name: "RSASSA-PKCS1-v1_5",
          hash: "SHA-256"
        },
        this.key as CryptoKey,
        signature,
        data
      );
    };
  }
}
