export function decode(raw: string): string {
  let input = raw.replace(/-/g, "+").replace(/_/g, "/");

  const pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error("Invalid length");
    }

    input += new Array(5 - pad).join("=");
  }

  return input;
}

export function decodeUint8Array(raw: string): Uint8Array {
  const encoded = decode(raw);

  return new Uint8Array(
    atob(encoded)
      .split("")
      .map((c) => c.charCodeAt(0))
  );
}
