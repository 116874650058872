import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import useSession from "./useSession";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

export default function OAuthCallback(): JSX.Element {
  const session = useSession();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    session?.callback().then(() => {
      setLoading(false);
    });
  }, [setLoading]);

  if (loading) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CircularProgress />
          <Typography>logging in...</Typography>
        </Grid>
      </Grid>
    );
  }

  return <Redirect to="/" />;
}
