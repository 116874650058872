import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Theme from "./Theme";
import BaseHeader from "./BaseHeader";
import BaseFooter from "./BaseFooter";
import Routes from "./Routes";

const useStyles = makeStyles(() => ({
  main: {
    minWidth: "100%",
    minHeight: "calc(100vh - 100px)",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    flexDirection: "row"
  }
}));

export default function Base(): JSX.Element {
  const classes = useStyles();

  return (
    <Router>
      <Theme>
        <BaseHeader />
        <main className={classes.main}>
          <Routes />
        </main>
        <BaseFooter />
      </Theme>
    </Router>
  );
}
