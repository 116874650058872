import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Login from "../auth/Login";
import OAuthCallback from "../auth/OAuthCallback";
import Hello from "./Hello";

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path="/oauth/callback">
        <OAuthCallback />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/hello">
        <Hello />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}
