import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SessionProvider from "./auth/SessionProvider";
import Login from "./auth/Login";
import OAuthCallback from "./auth/OAuthCallback";
import Base from "./pages/Base";
import config from "./config";
import { SessionOptions } from "./auth/Session";
import GraphProvider, { GraphProviderOptions } from "./graphql/GraphProvider";

const sessionOptions: SessionOptions = {
  clientId: config.OAUTH_CLIENT_ID,
  idpEndpointURL: config.IDP_ENDPOINT_URL
};

const graphOptions: GraphProviderOptions = {
  uri: config.GRAPH_ENDPOINT_URL
};

function App(): JSX.Element {
  return (
    <SessionProvider options={sessionOptions}>
      <GraphProvider options={graphOptions}>
        <Router>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/oauth/callback">
              <OAuthCallback />
            </Route>
            <Route path="/">
              <Base />
            </Route>
          </Switch>
        </Router>
      </GraphProvider>
    </SessionProvider>
  );
}

export default App;
