/* eslint-disable */
// generated file - do not modify
import JWK, { JWK_RAW } from "./JWK";
import JWKS from "./JWKS";

const JWKeys: JWKS = new JWKS([
  new JWK({
    alg: "RS256",
    e: "AQAB",
    kid: "T3Exj3hb+ry/UyIor4p0iNwnNKl3Mr/odIl1yuYky+0=",
    kty: "RSA",
    n: "tgadyhttWmuIGLvJafWgzx0eni37MECgoEOPy_pZoId3uiWyRlF5MyGXc99Mi5YHjsun0CG1E-ScO6u3zq7F8xnMU4xYHprfV3Ur_74DrMh7xoMqcH9ptUVdurAJ7vNDSM5p0bNsdzZhnd4PBnF2fZOnJwLJZtHrfxELVKs62CgtEkg_UeY3VDUC-6g-HazQgyQiN2bsGr7jO9FgFzFb_R5MmIUh0RU5X7i6uSAREOSCxd1RuSA2MztDY09CqU-6fMhCyqlf4YlinTDsXOvcDJZkEUKU2sVysueezZX6QW91ALhSZUIwvv44Aa5pATRrgOc1KZSQDU1556vkj1FZoQ",
    use: "sig"
  } as JWK_RAW),
  new JWK({
    alg: "RS256",
    e: "AQAB",
    kid: "uvGzIs0OBnGo+CEi7jKktx4hPkHV0mzW+1bezEC+J7Q=",
    kty: "RSA",
    n: "3KldGvhUBs9uq742elYnZMmkbWLnd1e7EbyIoWTN_h7if8NoyHwheevfDpl0FR4B3tsxDZupZDmoxkngnUV00DpZzKYysqvPxtzrLHoDXl65Czs0vVDWN_jR2as3LcmXlEZs6LBoM3ijBrU-NG5rby57HZk6dTbWIhF7EHsJ_vFQKVzZrZRCGCPagEDF9WTcxX2t8GNQJJQqfEZnVjaMqYOQwsSExYhTLciZeuCOAOo2K2bVLOHjSgpxOtA70ZWUPR_iw10vyBy3C098Mm8JnOy0Cyd9xQV_Mq6ZfH7q2QLvRZWwuwAi55NxPtMGuYN9UcPbd1E3_3mw_KPcdT9Ssw",
    use: "sig"
  } as JWK_RAW)
]);

export default JWKeys;
