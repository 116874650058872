import React from "react";
import Container from "@material-ui/core/Container";

export default function Home(): JSX.Element {
  return (
    <Container maxWidth="xs">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
        mollis risus ut ipsum elementum, a vehicula nisi auctor. Mauris mi nisi,
        tempus non mauris sit amet, ultrices rhoncus augue. Fusce interdum, quam
        vel elementum euismod, elit ex vestibulum velit, id rutrum arcu lorem
        vitae erat. Integer sed nisi a enim condimentum porta in quis sapien. In
        hac habitasse platea dictumst. Cras ullamcorper metus neque, ac
        porttitor magna vehicula et. Pellentesque fringilla orci et pulvinar
        sagittis. Quisque pharetra condimentum nulla, in ultrices felis
        convallis vitae. In egestas viverra eros, vel dignissim risus mattis in.
        Nullam vel venenatis felis, vel tristique elit. Integer et ex quis risus
        finibus molestie. Proin dolor metus, faucibus vel justo nec, gravida
        luctus nunc. Phasellus hendrerit luctus vestibulum. Sed vitae commodo
        sapien. Integer maximus risus ut ante accumsan, eu tempus arcu ultrices.
      </p>
      <p>
        Sed condimentum metus ac elementum mattis. In eros libero, rutrum et
        placerat a, molestie vel erat. Fusce pulvinar metus odio, euismod
        tristique diam finibus vitae. Maecenas vehicula justo id mi auctor
        lacinia. Integer maximus ipsum eget sapien dignissim malesuada.
        Vestibulum eu velit a elit porttitor fringilla ut ut dolor. Proin
        dignissim felis vel est rhoncus tempus eget sed tellus. Nullam non
        sodales arcu. Curabitur eu augue et enim ultrices dapibus vel eget ante.
        Suspendisse finibus aliquam nunc, eu tristique lorem porta non. Aenean
        aliquam eros vitae arcu volutpat, in dignissim ipsum eleifend. Aenean
        gravida sapien at nunc iaculis posuere.
      </p>

      <p>
        Nulla euismod enim ut volutpat imperdiet. Ut ac tempus dolor, id
        suscipit ex. Vestibulum laoreet neque id ipsum tempor rutrum. Maecenas
        placerat leo eget pulvinar sagittis. Fusce bibendum elit diam, accumsan
        pretium elit lacinia at. Curabitur sit amet dui luctus, bibendum arcu
        quis, tincidunt leo. Curabitur et metus condimentum, scelerisque odio
        quis, lobortis sem. Cras nec justo egestas, convallis urna id, semper
        magna. Nam vehicula justo quis quam hendrerit tristique. Nullam nec leo
        nunc. Nam nisi orci, malesuada ut nisi sed, fermentum efficitur mauris.
      </p>

      <p>
        Mauris ultricies imperdiet ullamcorper. Quisque vitae felis sem. In
        venenatis elit placerat velit posuere, vitae eleifend justo iaculis.
        Etiam placerat lacus eu pellentesque vehicula. Quisque vehicula
        malesuada rutrum. Donec consectetur tempus neque, vel finibus eros
        cursus et. Phasellus consequat sapien non pellentesque consectetur. In
        hac habitasse platea dictumst. Ut porta ipsum quis convallis venenatis.
        Donec ut ligula at massa posuere ornare. Aliquam at neque laoreet,
        scelerisque quam at, hendrerit odio.
      </p>

      <p>
        Duis sed magna elit. In molestie, mauris ut lobortis consectetur, eros
        sem aliquam ex, sed faucibus mi nisl eget dolor. Proin ut ex vitae
        tellus varius pretium id ac ante. Nam quis augue suscipit, vulputate
        nisi ut, venenatis ante. Nulla tempus, ipsum id volutpat placerat, eros
        diam pharetra nisl, et porttitor ante justo eget neque. Aenean dignissim
        pretium luctus. Donec porta diam sodales nunc viverra accumsan.
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia curae; Vivamus laoreet magna vitae turpis aliquam finibus.
        Curabitur porttitor venenatis sodales. Phasellus finibus sit amet tellus
        tempus scelerisque. Vestibulum aliquet leo sed enim tempor, eget
        convallis magna ultricies.
      </p>
    </Container>
  );
}
