const IDP_ENDPOINT_URL: string = process.env.REACT_APP_IDP_ENDPOINT_URL || "";
const OAUTH_CLIENT_ID: string = process.env.REACT_APP_OAUTH_CLIENT_ID || "";
const GRAPH_ENDPOINT_URL: string =
  process.env.REACT_APP_GRAPH_ENDPOINT_URL ||
  "https://graph.paraxanthine.ca/graphql";

export default {
  IDP_ENDPOINT_URL,
  OAUTH_CLIENT_ID,
  GRAPH_ENDPOINT_URL
};
