import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import useSession from "./useSession";

export default function Login(): JSX.Element {
  const session = useSession();

  const [loginURL, setLoginURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!session) return;

    session?.login().then((url) => {
      setLoginURL(url.toString());
    });
  }, [session, setLoginURL]);

  if (!loginURL) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CircularProgress />
          <Typography>...</Typography>
        </Grid>
      </Grid>
    );
  }

  window.history.replaceState(null, "", "/");
  window.location.assign(loginURL);

  return (
    <Grid container>
      <Grid item xs={12}>
        <CircularProgress />
        <Typography>
          Redirecting to <a href={loginURL}>login</a>
        </Typography>
      </Grid>
    </Grid>
  );
}
