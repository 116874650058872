import React from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";

type HideOnScrollProps = {
  children: React.ReactElement;
};

export default function HideOnScroll({
  children
}: HideOnScrollProps): JSX.Element {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
