import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

type ThemeProps = {
  children: JSX.Element[];
};

const themes = {
  $default: {},
  gross: {
    palette: {
      primary: {
        main: "#8d6e63"
      },
      secondary: {
        main: "#5d4037"
      }
    }
  },
  dark: {}
};

export default function Theme({ children }: ThemeProps): JSX.Element {
  const theme = createMuiTheme(themes.$default);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
}
