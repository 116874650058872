import React, { useState, useMemo } from "react";
import Session, { SessionOptions } from "./Session";

export interface SessionController {
  setSession: (session: Session) => void;
}

export const SessionControllerContext = React.createContext<SessionController>({
  setSession: () => {}
});
export const SessionContext =
  React.createContext<Session | undefined>(undefined);

type SessionProviderProps = {
  children: JSX.Element;
  options: SessionOptions;
};

export default function SessionProvider({
  children,
  options
}: SessionProviderProps): JSX.Element {
  const [session, setSession] = useState<Session>(new Session(options));

  const controller = useMemo(
    () => ({
      setSession: setSession
    }),
    [setSession]
  );

  return (
    <SessionControllerContext.Provider value={controller}>
      <SessionContext.Provider value={session}>
        {children}
      </SessionContext.Provider>
    </SessionControllerContext.Provider>
  );
}
