import hexrand from "../lib/hexrand";
import sha256 from "../lib/sha256";

export interface PKCE {
  code: string;
  hash: string;
  method: string;
}

export default async function generatePKCE(): Promise<PKCE> {
  const code = hexrand(128);
  const hash = await sha256(code);

  return {
    code: code,
    hash: hash,
    method: "S256"
  };
}
