import hexrand from "../lib/hexrand";
import formEncode from "../lib/formEncode";
import generatePKCE, { PKCE } from "./pkce";
import Token, { TokenRaw } from "./oauth/Token";
import JWKeys from "./oauth/JWKeys";

JWKeys.initialize()
  .then(() => {
    console.log("JWKS initialized");
  })
  .catch((ex) => {
    console.error(ex);
  });

export interface SessionOptions {
  clientId?: string;
  idpEndpointURL?: string;
}

class Session {
  private readonly clientId: string;
  private readonly idpEndpointURL: string;
  private $token?: Token;

  constructor(options: SessionOptions) {
    this.clientId = options.clientId || "";
    this.idpEndpointURL = options.idpEndpointURL || "";

    const token = window.localStorage.getItem("token");

    if (token && token !== "undefined") {
      this.token = new Token(JSON.parse(token) as TokenRaw);
    }
  }

  isValid(): boolean {
    return !!this.token;
  }

  private set token(value: Token | undefined) {
    this.$token = value;
    window.localStorage.setItem("token", (value || "undefined").toString());
  }

  private get token(): Token | undefined {
    return this.$token;
  }

  get username(): string | undefined {
    if (!this.isValid()) {
      return undefined;
    }

    return this.token?.username;
  }

  async login(): Promise<URL> {
    const state = hexrand(64);
    const pkce = await generatePKCE();

    window.sessionStorage.setItem(state, JSON.stringify(pkce));

    const redirectUri = new URL("/oauth/callback", window.location.origin);

    const loginURL = new URL(`/login`, this.idpEndpointURL);
    loginURL.searchParams.set("response_type", "code");
    loginURL.searchParams.set("client_id", this.clientId);
    loginURL.searchParams.set("redirect_uri", redirectUri.toString());
    loginURL.searchParams.set("state", state);
    loginURL.searchParams.set("code_challenge", pkce.hash);
    loginURL.searchParams.set("code_challenge_method", pkce.method);

    return loginURL;
  }

  async callback(): Promise<void> {
    const { search, origin } = window.location;
    window.history.replaceState(null, "", "/");

    const params = search.split("&").reduce((params: any, p: string) => {
      const [k, v] = p.split("=");

      params[k.replace("?", "")] = v;

      return params;
    }, {});

    if (!params.code) {
      return;
    }

    const pkceRaw = window.sessionStorage.getItem(params.state);
    if (!pkceRaw) {
      return;
    }

    const pkce = JSON.parse(pkceRaw) as PKCE;

    const redirectUri = new URL("/oauth/callback", origin);

    const tokenUrl = new URL("/oauth2/token", this.idpEndpointURL);

    const res = await fetch(tokenUrl.toString(), {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      },
      body: formEncode({
        grant_type: "authorization_code",
        code: params.code,
        client_id: this.clientId,
        redirect_uri: redirectUri.toString(),
        code_verifier: pkce.code
      })
    });

    if (res.status !== 200) {
      return;
    }

    const tokenRaw: TokenRaw = await res.json();

    if (!tokenRaw) {
      return;
    }

    const token = new Token(tokenRaw);

    const valid = await token.validate();

    if (!valid) {
      throw new Error("narp");
    }

    this.token = token;
  }

  logout(): void {
    this.token = undefined;
  }

  async refresh(): Promise<void> {
    if (!this.token) {
      return;
    }

    if (this.token?.expires && this.token?.expires > Date.now()) {
      return;
    }

    const tokenUrl = new URL("/oauth2/token", this.idpEndpointURL);

    const res = await fetch(tokenUrl.toString(), {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      },
      body: formEncode({
        grant_type: "refresh_token",
        client_id: this.clientId,
        refresh_token: this.token.refreshToken
      })
    });

    if (res.status !== 200) {
      return;
    }

    const tokenRaw: TokenRaw = await res.json();

    if (!tokenRaw) {
      return;
    }

    const token = new Token(tokenRaw);

    const valid = await token.validate();

    if (!valid) {
      throw new Error("narp");
    }

    this.token = token;
  }

  fetcher() {
    return async (
      input: RequestInfo,
      init?: RequestInit | undefined
    ): Promise<Response> => {
      const authedInit: RequestInit = init || {
        headers: {}
      };

      if (!this.isValid()) {
        await this.refresh();
      }

      authedInit.headers = {
        ...(authedInit.headers || {}),
        Authorization: `Bearer ${this.token?.accessToken.bearer}`
      };

      return fetch(input, authedInit);
    };
  }
}

export default Session;
